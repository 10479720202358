import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import {BottlesGroup} from '../../../assets/icons'

import css from './SectionHero.module.css';
import HeroSearchForm from './HeroSearchForm/HeroSearchForm';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';

const SectionHero = props => {
  const { rootClassName, className, history, intl} = props;

  const classes = classNames(rootClassName || css.root, className);
  const handleSubmit = (values) => {
    const searchParams = {
      keywords: values?.keywords
    };
    history.push(createResourceLocatorString('ResearchWineries', routeConfiguration(), {}, searchParams));
  }

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <span className={css.bottlesGroup}>
          <BottlesGroup />
        </span>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <div style={{width: '100%'}}>
          <HeroSearchForm
           rootClassName={css.searchBarWrapper}
           searchBarClassName={css.searchBar}
           searchBarIcon={css.searchIcon}
           onSubmit={handleSubmit}
           intl={intl}
          />
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
