import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import config from '../../config';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import ellipsUp from './ellips-up.png';
import ellipsDown from './ellips-down.png';
import box1 from './box1.jpg';
import box2 from './box2.jpg';
import box3 from './box3.jpg';
import box4 from './box4.jpg';
import box5 from './box5.jpg';
import box6 from './box6.jpg';
import Image1 from './Rectangle 104.png';
import Image2 from './Rectangle 105.png';
import Image3 from './Rectangle 106.png';
import Image4 from './Rectangle 107.png';
import Image5 from './Rectangle 108.png';
import Image6 from './Rectangle 109.png';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import facebookImage from '../../assets/sommly-auth-login-bg.jpg';
import twitterImage from '../../assets/sommly-auth-login-bg.jpg';
import sommlyHeroImage from '../../assets/sommly-hero.png';

import SectionHero from './SectionHero/SectionHero';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';
import SectionFilteredSearches from './SectionFilteredSearches/SectionFilteredSearches';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const schemaDescription = `Sommly is a social marketplace where wine lovers can engage with wineries, find and purchase the best wines, and expand their knowledge of the world of wine.`;
  const schemaImage = `${config.canonicalRootURL}${sommlyHeroImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title="Buy Wine Online -- DTC Wine Marketplace Of Artisanal Wineries"
      // facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      // twitterImages={[
      //   { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      // ]}
      schema={{
        '@context': 'https://sommly.com',
        '@type': 'WebPage',
        description: schemaDescription,
        name: 'Sommly',
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              rootClassName={css.heroRoot}
              className={css.hero}
              history={history}
              location={location}
              intl={intl}
            />
          </div>
          <section className={css.mainSection}>
            <div className={css.topTextContainer}>
              <p className={css.topTextcentered}>
                Sommly is a social marketplace where wine lovers can engage with wineries, find and
                purchase the best wines, and expand their knowledge of the world of wine.
              </p>
            </div>
            <div className={css.ellipsUp}>
              <img src={ellipsUp} alt="" />
            </div>
            <div className={css.ellipsDown}>
              <img src={ellipsDown} alt="" />
            </div>
            <div className={css.mainContainer} style={{ marginBottom: -400, marginTop: 75 }}>
              <h1 className={css.headingLevel1} style={{ marginBottom: 75 }}>
                Bring the Winery Experience to your Home
              </h1>
              <div className={css.mainContainerInner}>
                <div className={css.boxContainer}>
                  <img src={Image1} alt="" />

                  <div className={classNames(css.box, css.box3)}>
                    <div className={css.overlay}>
                      <h1 className={css.overlayTilte}>
                        Discover and purchase craft <br /> wines direct from the <br /> winery
                      </h1>
                      <SecondaryButton
                        rootClassName={css.overlayBtn}
                        onClick={() => {
                          history.push('/buy-wine');
                        }}
                      >
                        Buy Now
                      </SecondaryButton>
                    </div>
                  </div>
                </div>
                <div className={css.boxContainer}>
                  <img src={Image2} alt="" />

                  <div className={classNames(css.box, css.box3)}>
                    <div className={css.overlay}>
                      <h1 className={css.overlayTilte}>
                        Bring the winery experience <br />
                        to your house
                      </h1>
                      <SecondaryButton
                        rootClassName={css.overlayBtn}
                        style={{ marginTop: 50 }}
                        onClick={() => {
                          history.push('/about');
                        }}
                      >
                        About Sommly
                      </SecondaryButton>
                    </div>
                  </div>
                </div>
                <div className={css.boxContainer}>
                  <img src={Image3} alt="" />

                  <div className={classNames(css.box, css.box3)}>
                    <div className={css.overlay}>
                      <h1 className={css.overlayTilte}>
                        Discover wineries and <br /> plan your next wine country <br /> visit
                      </h1>
                      <SecondaryButton
                        rootClassName={css.overlayBtn}
                        onClick={() => {
                          history.push('/research-wineries');
                        }}
                      >
                        Research Wineries
                      </SecondaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={css.mainContainer} style={{ marginBottom: -400 }}>
              <h1 className={css.headingLevel1} style={{ marginBottom: 75 }}>
                Sommly for Wineries
              </h1>
              <div className={css.mainContainerInner}>
                <div className={css.boxContainer}>
                  <img src={Image4} alt="" />

                  <div className={classNames(css.box, css.box3, css.boxnew)}>
                    <div className={css.overlay}>
                      <h1 className={css.overlayTilte}>
                        Claim, customize, and list <br /> wines for sale on your page <br />
                        in minutes{' '}
                      </h1>
                      <SecondaryButton
                        rootClassName={css.overlayBtn}
                        onClick={() => {
                          history.push('/about');
                        }}
                      >
                        Learn More
                      </SecondaryButton>
                    </div>
                  </div>
                </div>
                <div className={css.boxContainer}>
                  <img src={Image5} alt="" />

                  <div className={classNames(css.box, css.box3, css.boxnew)}>
                    <div className={css.overlay}>
                      <h1 className={css.overlayTilte}>
                        Increase wine sales, club members, and profit
                        <br /> margins{' '}
                      </h1>
                      <SecondaryButton
                        rootClassName={css.overlayBtn}
                        onClick={() => {
                          history.push('/why-sommly');
                        }}
                      >
                        Why Sommly
                      </SecondaryButton>
                    </div>
                  </div>
                </div>
                <div className={css.boxContainer}>
                  <img src={Image6} alt="" />

                  <div className={classNames(css.box, css.box3, css.boxnew)}>
                    <div className={css.overlay}>
                      <h1 className={css.overlayTilte}>
                        Improve discoverability and
                        <br /> drive visits to your tasting <br />
                        room{' '}
                      </h1>
                      <SecondaryButton
                        rootClassName={css.overlayBtn}
                        onClick={() => {
                          history.push('/winery-plans');
                        }}
                      >
                        Plans & Pricing
                      </SecondaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={css.mainContainer}
              style={{
                marginBottom: 75,
              }}
            >
              <h1
                className={css.headingLevel1}
                style={{
                  marginBottom: 75,
                }}
              >
                Every Empty Bottle is Filled with Stories
              </h1>
              <div className={css.newmainContainerInner}>
                <div className={css.boxContainer}>
                  <div className={classNames(css.box, css.box4)}>
                    <div className={css.overlay}>
                      <h1 className={css.overlayTilte}>
                        Explore vineyards <br /> & wine regions
                      </h1>
                      <button
                        className={css.newOverlayBtn}
                        onClick={() => {
                          history.push('/research-wineries');
                        }}
                      >
                        Explore Now
                      </button>
                    </div>
                  </div>
                </div>
                <div className={css.boxContainer}>
                  <div className={classNames(css.box, css.box5)}>
                    <div className={css.overlay}>
                      <h1 className={css.overlayTilte}>
                        Discover the perfect <br /> wine for any occasion
                      </h1>
                      <button
                        className={css.newOverlayBtn}
                        onClick={() => {
                          history.push('/buy-wine');
                        }}
                      >
                        Shop Now
                      </button>
                    </div>
                  </div>
                </div>
                <div className={css.boxContainer}>
                  <div className={classNames(css.box, css.box6)}>
                    <div className={css.overlay}>
                      <h1 className={css.overlayTilte}>Add your winery to Sommly</h1>
                      <button
                        className={css.newOverlayBtn}
                        onClick={() => {
                          window.location.href =
                            'https://nuxf70br87u.typeform.com/to/DStByZgN?typeform-source=sommly.com';
                        }}
                      >
                        Add Winery
                      </button>
                    </div>
                  </div>
                </div>
                <div className={css.boxContainer}>
                  <div className={classNames(css.box, css.box7)}>
                    <div className={css.overlay}>
                      <h1 className={css.overlayTilte}>Questions? Our team is here to help</h1>
                      <button
                        className={css.newOverlayBtn}
                        onClick={() => {
                          history.push('/about#contactus');
                        }}
                      >
                        Contact Us
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
