import React, { useRef } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { SearchIcon } from '../../../../assets';
import {Form} from '../../../../components'
import css from './HeroSearchForm.module.css';

const HeroSearchForm = ({
  rootClassName,
  searchBarClassName,
  searchBarIcon,
  onSubmit,
  ...restOfProps
}) => {

  const inputRef = useRef();

  const handleSubmit = (values) =>  {
    onSubmit({ keywords: values.keywords });
    inputRef.current?.blur();
  }

  return (
    <FinalForm
        {...restOfProps}
        onSubmit={handleSubmit}
        render={formRenderProps => {
          const {
            intl,
            handleSubmit,
          } = formRenderProps;

          return (
            <Form style={{display: "flex", alignItems: "center", justifyContent: "center"}} onSubmit={handleSubmit}  enforcePagePreloadFor="SearchPage">
              <div className={rootClassName}>
                <SearchIcon className={searchBarIcon} onClick={handleSubmit} />
                <Field
                  name="keywords"
                  render={({ input, meta }) => {
                    return (
                      <input
                        className={searchBarClassName}
                        {...input}
                        id="keyword-search"
                        ref={inputRef}
                        type="text"
                        placeholder={intl?.formatMessage({
                          id: 'TopbarSearchForm.placeholder',
                        })}
                        autoComplete="off"
                      />
                    );
                  }}
                />
              </div>
          </Form>
        );
      }}
    />
  )
}

export default HeroSearchForm
